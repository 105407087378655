// Place all the styles related to the Accespatients controller here.
// They will automatically be included in application.css.
// You can use Sass (SCSS) here: http://sass-lang.com/

strong {
  font-weight: bold;
}

body {
  font-family: Arial, sans-serif;
}

#full-height {
  height: calc(100vh - 90px);
}

#hoverable {
  background-color: transparent;
}

#hoverable:hover > * {
  background-color: #eaeaea;
  border-radius: 20px;
  box-shadow: 0 0 0 8px #eaeaea;
  cursor: pointer;
}


@media #{$medium-and-down} {
  body {
    font-size: 14px;
  }
  span {
    font-size: 1em;
  }

  [type="radio"]:not(:checked),
  [type="radio"]:checked {
    width: 25px; /* Adjust width as needed */
    height: 25px; /* Adjust height as needed */
  }

  [type="radio"]:not(:checked) + span:after,
  [type="radio"]:checked + span:after {
    width: 25px; /* Adjust width as needed */
    height: 25px; /* Adjust height as needed */
  }

  [type="radio"]:checked + span:before,
  [type="radio"]:not(:checked) + span:before {
    width: 25px; /* Adjust width as needed */
    height: 25px; /* Adjust height as needed */
  }

  [type="radio"] + span {
    line-height: 15px !important;
    font-size: 1rem !important;
    padding-left: 50px !important;
    margin-top: 20px !important;
  }

  input[type=text]:not(.browser-default) {
    height: 14px;
    margin-top: 20px;
    font-size: 1rem;
  }

  input[type=text]:not(.browser-default)::placeholder {
    font-size: 1rem;
  }

  .btn {
    font-size: 1rem;
    padding: 0;
    height: 50px;
    line-height: 50px;
    border-radius: 10px;
    min-width: 200px;
  }

}

h1 {
  font-size: 1.8em;
}

h2 {
  font-size: 1.4em;
}

h3 {
  font-size: 1.38em;
}

h4 {
  font-size: 1.3em;
}

h5 {
  font-size: 1em;
}

h6 {
  font-size: 0.85em;
}

.pdf-page {
  padding: 50px 75px;
  background-color: white;
  font: 12pt "Arial";
  width: 21cm;
  height: 29.7cm;
  display: block;
  margin: auto;
}

.pdf-document {
  font: 10pt "Arial";
  display: block;
  width: 21cm;
  margin: 0;
  padding-inline: 40px;
}

.prevent-break {
  page-break-inside: avoid;
}

.pdf-footer {
  vertical-align: bottom;
}
