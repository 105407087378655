.circle {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%; /* This makes it a perfect circle */
  color: white; /* Color of the text */
  text-align: center;
  line-height: 50px; /* This is to center the text vertically */
  font-family: Arial, sans-serif; /* Choose your desired font-family */
  font-weight: bold; /* Optional, but makes the text more readable */
}

.user_dropdown_header {
  border: 2px solid #eeeeee;
}