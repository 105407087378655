/*
 * This is a manifest file that'll be compiled into application.css, which will include all the files
 * listed below.
 *
 * Any CSS and SCSS file within this directory, lib/assets/stylesheets, or any plugin's
 * vendor/assets/stylesheets directory can be referenced here using a relative path.
 *
 * You're free to add application-wide styles to this file and they'll appear at the bottom of the
 * compiled file so the styles you add here take precedence over styles defined in any other CSS/SCSS
 * files in this directory. Styles in this file should be added after the last require_* statement.
 * It is generally better to create a new file per style scope.
 *
 *= require_self
 *= require_tree .
 */
@import url('https://fonts.googleapis.com/css?family=Kreon:400|Raleway:500');
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp");

$primary-color: #0FA3B1 !default;
$secondary-color: #00A7E1 !default;
$lastcolor: #A5D8FF;

// Defining global vars
:root {
  --surface-color: white;
  --nav-height: 90px;
  --nav-unfinished-height: 83px;
}

.card {
  background-color: white;
}

p, h1, h2, h3, h4, h5 {
  margin-bottom: 1.5em;
}

h1, h2, h3, h4, h5, h6 {font-family: 'Arial', sans-serif;}
body {
  font-family: 'Raleway', sans-serif;
  //font-style: 19px;
  line-height: 1.5em;
}

@media #{$medium-and-down} {
  body{font-size: 50px;}
  span {font-size: 1em;}

  [type="radio"]:not(:checked),
  [type="radio"]:checked {
    width: 50px;  /* Adjust width as needed */
    height: 50px; /* Adjust height as needed */
  }

  [type="radio"]:not(:checked) + span:after,
  [type="radio"]:checked + span:after {
    width: 50px; /* Adjust width as needed */
    height: 50px; /* Adjust height as needed */
  }

  [type="radio"]:checked + span:before,
  [type="radio"]:not(:checked) + span:before {
    width: 50px; /* Adjust width as needed */
    height: 50px; /* Adjust height as needed */
  }

  [type="radio"] + span {
    line-height: 50px!important;
    font-size: 3rem!important;
    padding-left: 80px!important;
    margin-top: 20px!important;
  }

  input[type=text]:not(.browser-default) {
    height: 120px;
    margin-top: 20px;
    font-size: 3rem;
  }

  input[type=text]:not(.browser-default)::placeholder {
    font-size: 3rem;
  }

  .btn {
    font-size: 3rem;
    padding: 0 3rem;
    height: 120px;
    line-height: 120px;
    border-radius: 10px;
  }
  //
  //li {
  //  font-size: 3rem;
  //}
  //
  //.material-icons {
  //  font-size: 5rem!important;
  //}
  //
  //.material-icons.tiny {
  //  font-size: 3rem!important;
  //}
}

h1 {font-size: 1.8em;}
h2 {font-size: 1.4em;}
h3 {font-size: 1.38em;}
h4 {font-size: 1.3em;}
h5 {font-size: 1em;}
h6 {font-size: 0.85em;}


strong {
  font-weight: bold;
}

#full-height {
  width: 100%;
  height: calc(100vh - var(--nav-height) - var(--nav-unfinished-height));
}

nav {
  height: var(--nav-height);
  background: white;
  font-family: Arial, sans-serif;

  img {
    margin-top: 23px;
    margin-left: 35px;
  }

  a {
    font-size: 18px !important;
  }

  ul {
    li {
      a {
        color: black;
      }
    }
  }

}

nav, nav .nav-wrapper, nav a.sidenav-trigger, nav a.sidenav-trigger {
  line-height: 90px;
}

nav li a {
  outline: none;
}

$nav-dd-color: #eeeeee;
nav li a:hover, .dropdown-content li a {
  color: #000;
  background-color: $nav-dd-color;
}

nav .dropdown-content li {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #000;
  background-color: white;
  h5 {
    margin: 0;
    padding: 0;
  }
  a {
    background-color: $nav-dd-color;
    height: 100% !important;
    width: 100% !important;
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
}

nav .dropdown-content li a:hover {
  font-weight: bolder;
  color: #000;
  background-color: white !important;
}

nav .dropdown-content li:hover {
  background-color: white !important;
}


nav .dropdown-content {
  box-shadow: none;
  border: thin solid $nav-dd-color;
  border-top: #FFF;
}

.floating-alert {
  position: fixed;
  overflow: hidden;
  top: 10px;
  left: 10px;
  padding: 5px;
  z-index: 1;
}

.alert-consult {
  position: fixed;
  overflow: hidden;
  bottom: 0;
  left: 0;
  padding: 5px;
  z-index: 1;
}

.flash {
  animation: flash 1s linear infinite;
}

@keyframes flash {
    0% {opacity: 1;}
    50% {opacity: 0.5;}
    100% {opacity: 1;}
}


.full-div-img {
  max-width: 90%;
  // display: block;
  max-width: 99%;
  height: auto !important;
  margin-left: auto;
  margin-right: auto;
}

.image-modal-meet {
  max-height: 100% !important;
  overflow-y: hidden;
  top: 2% !important;
}

.tabs-mesures-synthese-v2 tr td, .tabs-mesures-synthese-v2 tr th {
  text-align: center;
}

.controle-bg-red {
  background: #ffcdd2;
  border-top: solid 3px red;
  box-shadow: 0 0 5px 0;
}

.pin-auth div input {
  max-width: 15% !important;
  width: 15% !important;
  display: inline-block;
  border-left: 1px solid rgb(158, 158, 158) !important;
  border-top: 1px solid rgb(158, 158, 158) !important;

  &:nth-last-child(1) {
    border-right: 1px solid rgb(158, 158, 158) !important;

  }

  .modal {
    border-radius: 30px;
  }

}

#hoverable {
  background-color: transparent;
}

#hoverable :hover {
  background-color: #eaeaea;
  border-radius: 20px;
  box-shadow: 0 0 0 8px #eaeaea;

}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}

#bold-on-hover:hover {
  font-weight: bolder;
}

#hide-if-not-hovered {
  opacity: 0;
  height: 10px;
}

#hide-if-not-hovered:hover {
  opacity: 1;
  height: inherit;
  transition: 0.5s;
}

#blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0.3;
  }
}

#clickable:hover {
  box-shadow: 0 0 0 8px #eaeaea !important;
  cursor: pointer;
}

.clickable:hover {
  opacity: 0.5;
  cursor: pointer;
}

.rotating {
  /* Set the animation properties */
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  from {
    /* Start the rotation from 0 degrees */
    transform: rotate(360deg);
  }
  to {
    /* Rotate the icon by 360 degrees */
    transform: rotate(0deg);
  }
}

#unfinishedConsultation {
  height: var(--nav-unfinished-height);
}

.popup-content {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 0 5px 0;
}

.show-on-hover {
  position: fixed;
  left: 0;
  right: 0;
  top: -80px;
  z-index: 1000;
  transition: all 0.3s ease-in-out;
}


.show-on-hover:hover {
  transform: translateY(80px);
  background: white;
}

.navlink > a.active {
  background-color: #d7d6d6 !important;
}
