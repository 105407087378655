#display_block_html {
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-height: fit-content;
  p {
    margin: 0!important;
    font-size: 1.2em;
  }
}

#display_block_html * {
  max-width: 100%;
  max-height: 100%;
}

#preview_block_html {
  margin-left: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 100%;
  min-height: fit-content;
  p {
    margin: 0!important;
  }
}

#preview_block_html * {
  max-width: 210mm;
  max-height: 297mm;
}

.display_block {
  p {
    margin: 0!important;
  }
}